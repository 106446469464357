module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_IE","url":"http://saas.ie/","site_name":"SAAS - Sponsor An Africa Scholar"},"twitter":{"handle":"@danielotienoke","site":"@danielotienoke","cardType":"https://saas.ie/static/saas-777abe5bdc607226f8f7aeffd933f9fb.png"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
