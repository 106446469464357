exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donation-js": () => import("./../../../src/pages/donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-events-fun-day-js": () => import("./../../../src/pages/events/fun-day.js" /* webpackChunkName: "component---src-pages-events-fun-day-js" */),
  "component---src-pages-events-girls-forum-js": () => import("./../../../src/pages/events/girls-forum.js" /* webpackChunkName: "component---src-pages-events-girls-forum-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-maths-camp-2024-day-five-js": () => import("./../../../src/pages/events/maths-camp/2024/day-five.js" /* webpackChunkName: "component---src-pages-events-maths-camp-2024-day-five-js" */),
  "component---src-pages-events-maths-camp-2024-day-four-js": () => import("./../../../src/pages/events/maths-camp/2024/day-four.js" /* webpackChunkName: "component---src-pages-events-maths-camp-2024-day-four-js" */),
  "component---src-pages-events-maths-camp-2024-day-one-js": () => import("./../../../src/pages/events/maths-camp/2024/day-one.js" /* webpackChunkName: "component---src-pages-events-maths-camp-2024-day-one-js" */),
  "component---src-pages-events-maths-camp-2024-day-three-js": () => import("./../../../src/pages/events/maths-camp/2024/day-three.js" /* webpackChunkName: "component---src-pages-events-maths-camp-2024-day-three-js" */),
  "component---src-pages-events-maths-camp-2024-day-two-js": () => import("./../../../src/pages/events/maths-camp/2024/day-two.js" /* webpackChunkName: "component---src-pages-events-maths-camp-2024-day-two-js" */),
  "component---src-pages-events-maths-camp-2024-index-js": () => import("./../../../src/pages/events/maths-camp/2024/index.js" /* webpackChunkName: "component---src-pages-events-maths-camp-2024-index-js" */),
  "component---src-pages-events-parents-meeting-js": () => import("./../../../src/pages/events/parents-meeting.js" /* webpackChunkName: "component---src-pages-events-parents-meeting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-donor-visits-2021-js": () => import("./../../../src/pages/news/donor-visits/2021.js" /* webpackChunkName: "component---src-pages-news-donor-visits-2021-js" */),
  "component---src-pages-news-donor-visits-2024-js": () => import("./../../../src/pages/news/donor-visits/2024.js" /* webpackChunkName: "component---src-pages-news-donor-visits-2024-js" */),
  "component---src-pages-news-donor-visits-index-js": () => import("./../../../src/pages/news/donor-visits/index.js" /* webpackChunkName: "component---src-pages-news-donor-visits-index-js" */),
  "component---src-pages-news-food-program-js": () => import("./../../../src/pages/news/food-program.js" /* webpackChunkName: "component---src-pages-news-food-program-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-moses-onyango-js": () => import("./../../../src/pages/news/moses-onyango.js" /* webpackChunkName: "component---src-pages-news-moses-onyango-js" */),
  "component---src-pages-news-newsletters-js": () => import("./../../../src/pages/news/newsletters.js" /* webpackChunkName: "component---src-pages-news-newsletters-js" */),
  "component---src-pages-news-top-students-2021-js": () => import("./../../../src/pages/news/top-students-2021.js" /* webpackChunkName: "component---src-pages-news-top-students-2021-js" */),
  "component---src-pages-students-current-js": () => import("./../../../src/pages/students/current.js" /* webpackChunkName: "component---src-pages-students-current-js" */),
  "component---src-pages-students-need-sponsorship-js": () => import("./../../../src/pages/students/need-sponsorship.js" /* webpackChunkName: "component---src-pages-students-need-sponsorship-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

